import { useEffect, useState } from 'react';
import { nativeBridge } from 'utils/nativeBridge';

const baseInitOptions = {
  onLoad: 'check-sso',
  pkceMethod: 'S256',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
};

const getInitOptions = async () => {
  if (!nativeBridge.isWebViewBridgeAvailable) {
    return baseInitOptions;
  }

  const nativeTokens = await nativeBridge.getTokens();

  return {
    ...baseInitOptions,
    token: nativeTokens.accessToken,
    refreshToken: nativeTokens.refreshToken,
    checkLoginIframe: false,
  };
};

export const useKeycloakInitOptions = () => {
  const [initOptions, setInitOptions] = useState(null);

  useEffect(() => {
    (async () => {
      const options = await getInitOptions();
      setInitOptions(options);
    })();
  }, []);

  return { initOptions };
};
