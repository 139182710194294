import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { format } from 'date-fns';
import { equals, isEmpty, omit } from 'ramda';

import { PHRApi } from 'api';
import { Roles, useAuth } from 'Auth';
import { usePublicResource } from 'hooks';
import { downloadFile, measurementUnit } from 'utils';
import { PHR_DATA_TYPE, phrApiEndpointMap, SYNC_STATUSES } from 'my-phr/const';
import features from 'features';
import {
  initialStateFiltersProvider,
  SOURCE_TYPES,
} from 'my-phr/context/FiltersProvider';
import {
  useIsShared,
  useSharedFilteredCardDataById,
  useSharedObservationById,
  useSharedStoryBlock,
} from 'my-phr/pages/Sharing/hooks';
import { sortByLastSynced } from 'utils/sources';
import {
  ucumConvertValues,
  validateAndConvertToSelf,
} from '../utils/conversion';
import { useCombinedData } from './dataParseHooks';

export const phrKeys = {
  all: () => ['phr'],
  dataDownload: () => ['phr-data-download'],
  sources: (search, queryParams) => ['phr-sources', search, queryParams],
  connected: () => ['connected-sources'],
  terra: (organizationId) => ['terra', organizationId],
  timeline: () => [...phrKeys.all(), 'timeline'],
  timelineTags: () => [...phrKeys.all(), 'timeline-tags'],
  timelineBySearchTerm: (searchTerm) => [...phrKeys.timeline(), searchTerm],
  isTimelineEmpty: () => [...phrKeys.timeline(), 'is-timeline-empty'],
  storyblock: (ids) => ['storyblock', ids],
  storyblockById: (id) => ['storyblockById', id],

  dashboard: () => [...phrKeys.all(), 'dashboard'],
  observations: (params) => [...phrKeys.all(), 'observations', ...params],
  dashboardHighlights: () => [...phrKeys.dashboard(), 'highlights'],
  dashboardHighlightsOptions: () => [
    ...phrKeys.dashboardHighlights(),
    'options',
  ],
  cardDataById: (id) => [...phrKeys.all(), 'card', id],
  filteredCardDataById: (id, startDate, endDate, limit, sort) => [
    ...phrKeys.cardDataById(id),
    startDate,
    endDate,
    limit,
    sort,
  ],
  cardPaginatedDataById: (id, page, size, sortBy) => [
    ...phrKeys.cardDataById(id),
    page,
    size,
    sortBy,
  ],
  cardById: (id) => [...phrKeys.all(), 'card', id],
  dashboardPreferences: () => [...phrKeys.dashboard(), 'preferences'],
  dashboardUnusedPreferences: () => [
    ...phrKeys.dashboardPreferences(),
    'unused',
  ],
  dashboardTopics: () => [...phrKeys.dashboardPreferences(), 'topics'],
};

const REFETCH_INTERVAL = 10000;

const setSelectedOptions = (arr, selected) => {
  return arr.map((item) => {
    const val = Array.isArray(item.value) ? item.value[0] : item.value;
    if (selected?.includes(val)) {
      return { ...item, selected: true };
    }
    return item;
  });
};

export function useExistingTags(options) {
  return useQuery(
    phrKeys.timelineTags(),
    async () => PHRApi.getExistingTags(),
    options
  );
}

export function useCategories(selected) {
  return useMemo(() => {
    return setSelectedOptions(
      [
        { label: 'my-phr.types.note_one', value: ['NOTE'] },
        { label: 'my-phr.types.allergy_one', value: ['ALLERGY'] },
        { label: 'my-phr.types.condition_one', value: ['CONDITION'] },
        {
          label: 'my-phr.types.observation',
          value: ['OBSERVATION', 'OBSERVATION_PANEL'],
        },
        { label: 'my-phr.types.medication_one', value: ['MEDICATION'] },
        { label: 'my-phr.types.procedure_one', value: ['PROCEDURE'] },
        { label: 'my-phr.types.vaccine_one', value: ['VACCINE'] },
      ],
      selected
    );
  }, [selected]);
}

function useEnabledTimelineSources() {
  const { hasRole } = useAuth();
  const wearablesEnabled = features.PHR.PHR_WEARABLES_ENABLED;
  const emrEnabled = features.PHR.PHR_EMRS_ENABLED;
  const ocrEnabled = Boolean(features.PHR.OCR_ENABLED || hasRole(Roles.ADMIN));

  return { wearablesEnabled, emrEnabled, ocrEnabled };
}

function getDefaultFilterSources(enabledSources) {
  const { wearablesEnabled, emrEnabled, ocrEnabled } = enabledSources;
  return [
    wearablesEnabled && SOURCE_TYPES.WEARABLE,
    emrEnabled && SOURCE_TYPES.EMR,
    SOURCE_TYPES.MANUAL,
    ocrEnabled && SOURCE_TYPES.OCR,
  ].filter(Boolean);
}

const fetchAndCacheStoryblocks = async (storyblockIds, queryClient) => {
  const cachedStoryblocksMap = {};
  const missingStoryblockIds = [];

  storyblockIds.forEach((id) => {
    const cached = queryClient.getQueryData(phrKeys.storyblockById(id));
    if (cached) {
      cachedStoryblocksMap[id] = cached;
    } else {
      missingStoryblockIds.push(id);
    }
  });

  if (missingStoryblockIds.length) {
    const fetchedStoryblocks = await PHRApi.getStoryblocksByIds(
      encodeURI(missingStoryblockIds)
    );
    fetchedStoryblocks.forEach((storyblock) => {
      queryClient.setQueryData(
        phrKeys.storyblockById(storyblock.id),
        storyblock
      );
      cachedStoryblocksMap[storyblock.id] = storyblock;
    });
  }
  return Object.values(cachedStoryblocksMap);
};
const TIMELINE_PAGE_SIZE = 25;

export function useTimeline(filters, businessIds) {
  const applyUnitsToAllItems = useApplyUnits();
  const enabledSources = useEnabledTimelineSources();
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(0);

  const querySources = useMemo(() => {
    const hasSelectedBusinessIds = Object.values(businessIds)
      .flat()
      .some(({ selected }) => selected);
    const areFiltersEmpty = isEmpty(filters.sources) && !hasSelectedBusinessIds;

    return areFiltersEmpty
      ? getDefaultFilterSources(enabledSources)
      : filters.sources;
  }, [filters, businessIds, enabledSources]);

  const timelineQuery = useInfiniteQuery(
    phrKeys.timelineBySearchTerm(
      filters ? JSON.stringify({ ...filters, sources: querySources }) : ''
    ),
    ({ pageParam }) => {
      const allowedBusinessIdsSet = new Set(
        Object.values(businessIds)
          .flat()
          .map((userSource) => userSource.value)
      );
      const filteredBusinessIdsSet = querySources.reduce((set, source) => {
        businessIds[source]?.forEach((userSource) =>
          set.delete(userSource.value)
        );
        return set;
      }, new Set(filters.userSourceBusinessIds));
      const filteredBusinessIds = Array.from(
        filteredBusinessIdsSet.intersection(allowedBusinessIdsSet)
      );
      const queryParams = {
        ...filters,
        sources: querySources,
        userSourceBusinessIds: filteredBusinessIds,
        size: TIMELINE_PAGE_SIZE,
        page: pageParam,
      };
      return PHRApi.getMy(queryParams).then(applyUnitsToAllItems);
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        const { last, number } = lastPage;
        if (last) return;
        return number + 1;
      },
      onSuccess: (data) => {
        const newPage = data.pages.length - 1;
        setCurrentPage(newPage);
      },
    }
  );

  const timelineLoadedPagesCount = useMemo(() => {
    return timelineQuery?.data?.pages?.length || 0;
  }, [timelineQuery?.data?.pages]);

  const storyblocksQuery = useInfiniteQuery(
    phrKeys.storyblock(),
    async ({ pageParam = 0 }) => {
      const timelineContent =
        timelineQuery?.data?.pages[pageParam]?.content || [];

      const idsSet = new Set();

      timelineContent.forEach((item) => {
        if (item?.observations?.length) {
          item.observations.forEach((observation) => {
            observation.storyblockId && idsSet.add(observation.storyblockId);
          });
        }
        item?.storyblockId && idsSet.add(item.storyblockId);
      });

      const storyblockIds = Array.from(idsSet);
      return storyblockIds.length
        ? fetchAndCacheStoryblocks(storyblockIds, queryClient)
        : [];
    },
    {
      enabled: Boolean(timelineQuery.data),
      getNextPageParam: (lastPage, allPages) =>
        allPages.length < timelineLoadedPagesCount
          ? allPages.length
          : undefined,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (timelineQuery.data) {
      storyblocksQuery.fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const storyblocksMap = useMemo(() => {
    const storyblocks = storyblocksQuery?.data?.pages?.flat() || [];
    return storyblocks.reduce((map, storyblock) => {
      if (storyblock) {
        map[storyblock.id] = storyblock;
      }
      return map;
    }, {});
  }, [storyblocksQuery?.data?.pages]);

  const combinedData = useMemo(() => {
    if (!timelineQuery.data || !storyblocksQuery.data) return null;
    return timelineQuery?.data?.pages?.map((timelinePage) => {
      return {
        ...timelinePage,
        content: timelinePage.content.map((timelineContent) => {
          const observationsWithStoryblocks = timelineContent?.observations
            ?.length
            ? timelineContent.observations.map((observation) => ({
                ...observation,
                storyblock: storyblocksMap?.[observation?.storyblockId],
              }))
            : [];

          return timelineContent?.observations?.length
            ? {
                ...timelineContent,
                observations: observationsWithStoryblocks,
                storyblock: storyblocksMap?.[timelineContent?.storyblockId],
              }
            : timelineContent;
        }),
      };
    });
  }, [timelineQuery.data, storyblocksQuery.data, storyblocksMap]);

  const isLoading = timelineQuery.isLoading || storyblocksQuery.isLoading;
  const isError = timelineQuery.isError || storyblocksQuery.isError;
  const error = timelineQuery.error || storyblocksQuery.error;

  const refetchTimeline = useCallback(async () => {
    await timelineQuery.refetch();
    await storyblocksQuery.refetch();
  }, [storyblocksQuery, timelineQuery]);

  useEffect(() => {
    if (timelineQuery.data) {
      storyblocksQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, timelineQuery.data]);

  if (isLoading) {
    return { isLoading, isError: false, error: null, combinedData: null };
  }

  if (isError) {
    return { isLoading: false, isError: true, error, combinedData: null };
  }

  const dataWithStoryblocks = { ...timelineQuery.data, pages: combinedData };

  return {
    ...timelineQuery,
    dataWithStoryblocks,
    storyblocksMap,
    refetchTimeline,
  };
}

export function useIsTimelineEmpty() {
  const enabledSources = useEnabledTimelineSources();

  const params = {
    ...initialStateFiltersProvider,
    sources: getDefaultFilterSources(enabledSources),
  };

  const { data, ...rest } = useQuery(
    phrKeys.isTimelineEmpty(),
    () => PHRApi.getMy({ ...params, size: 1, page: 0 }),
    {
      select: (data) => data?.empty ?? true,
    }
  );

  return { isTimelineEmpty: data, ...rest };
}

export function useTimelineDataStateUpdate() {
  const queryClient = useQueryClient();

  const update = useCallback(
    ({ currentTimelineFilters: filters, id }) => {
      const queryKey = phrKeys.timelineBySearchTerm(
        filters ? JSON.stringify(filters) : ''
      );

      queryClient.setQueryData(queryKey, ({ pages, pageParams }) => {
        const updatedPages = pages.map((page) => ({
          ...page,
          content: page.content.map((item) => {
            // Setting the deleted entry to null, so when a new page is fetched it doesn't skip an entry
            if (item?.id === id) {
              return null;
            }
            return item;
          }),
        }));

        // Resetting queries for when the filters or page changes
        queryClient.resetQueries(phrKeys.timeline(), {
          predicate: (query) => !equals(query.options.queryKey, queryKey),
        });
        return { pageParams, pages: updatedPages };
      });
    },
    [queryClient]
  );

  return update;
}

export function usePHREntryDelete() {
  const { mutateAsync: deleteEntry, isLoading: isLoadingDelete } = useMutation(
    (data) => {
      const dataType = phrApiEndpointMap[data?.type];
      const cardId = data?.id;

      if (!dataType) {
        throw new Error(`No data type with value '${dataType}' found!`);
      }

      if (!cardId) {
        throw new Error(`No id provided!`);
      }

      return PHRApi.deleteEntry(dataType, cardId);
    }
  );

  return { deleteEntry, isLoadingDelete };
}

export function usePHRDataDownload() {
  const { refetch: downloadPHRData, isLoading: isDownloading } = useQuery(
    phrKeys.dataDownload(),
    PHRApi.downloadData,
    {
      enabled: false,
      onError: console.error,
      onSuccess: (data) => {
        const today = format(new Date(), 'yyyyMMddkkmm');
        downloadFile(
          data,
          `StoryMDPHRData${today}.zip`,
          downloadFile.MIME_TYPES.ZIP
        );
      },
    }
  );
  return {
    downloadPHRData,
    isDownloading,
  };
}

export function useDashboardHighlightsOptions(config) {
  const { data, isLoading, isFetching, isFetched } = useQuery(
    phrKeys.dashboardHighlightsOptions(),
    PHRApi.getDashboardHighlights,
    { select: (data) => data.filter((x) => Boolean(x.id)), ...config }
  );
  return { data, isLoading, isFetching, isFetched };
}

export function useApplyUnits() {
  const applyImperialUnits = useApplyImperialUnits();
  const applyMetricUnits = useApplyMetricUnits();
  const func = useCallback(
    (data) => {
      const dataWithObservables = [].concat(data?.content ?? data);

      dataWithObservables
        .flatMap((x) => {
          if (x?.dashboardPreferenceDtos?.length) {
            return x.dashboardPreferenceDtos.flatMap((y) => y.items || y);
          }
          return x.items || x;
        })
        .forEach((x) => {
          if (x.observations) {
            func(x.observations);
          }
          applyUnitLabel(x);
          applyImperialUnits(x);
          applyMetricUnits(x);
        });

      return data;
    },
    [applyImperialUnits, applyMetricUnits]
  );
  return func;
}

function applyUnitLabel(observation) {
  const { unit, value } = observation;
  const convertedToSelf = validateAndConvertToSelf({
    unit,
    value,
  });
  observation.unitLabel = convertedToSelf.unit;
}

function applyUnits(type, measurementSystem, observation) {
  const unit = observation.unit;
  const toUnit = measurementSystem?.[unit];
  setUnits(type, observation, observation);
  if (toUnit) {
    const { conversionResult, ...convertedImperial } = ucumConvertValues({
      fromUnit: unit,
      fromValue: observation.value,
      toUnit,
    });
    if (conversionResult?.status === 'succeeded') {
      setUnits(type, observation, {
        unit: toUnit,
        unitLabel: convertedImperial.convertedUnitSymbol,
        value: convertedImperial.convertedValue,
      });
    }
  }
}

function setUnits(type, observation, { unit, unitLabel, value }) {
  observation[type + '_unit'] = unit;
  observation[type + '_unitLabel'] = unitLabel;
  observation[type + '_value'] = value;
}

function useApplyImperialUnits() {
  const measurementSystemsToImperial = usePublicResource(
    '/measurementSystem.json'
  )?.data?.['measurementSystems'];
  return useCallback(
    (observation) =>
      applyUnits(
        measurementUnit.IMPERIAL,
        measurementSystemsToImperial,
        observation
      ),
    [measurementSystemsToImperial]
  );
}

function useApplyMetricUnits() {
  const measurementSystemsToImperial = usePublicResource(
    '/measurementSystem.json'
  )?.data?.['measurementSystems'];
  const measurementSystemsToMetric = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(measurementSystemsToImperial || {}).map((a) =>
          a.reverse()
        )
      ),
    [measurementSystemsToImperial]
  );

  return useCallback(
    (observation) =>
      applyUnits(
        measurementUnit.METRIC,
        measurementSystemsToMetric,
        observation
      ),
    [measurementSystemsToMetric]
  );
}

function useFilteredCardDataById(
  { id, startDate, endDate, limit, sort },
  config
) {
  return useQuery(
    phrKeys.filteredCardDataById(
      id,
      startDate,
      endDate,
      limit,
      (sort = 'effectiveTs,desc')
    ),
    () =>
      PHRApi.getFilteredCardDataById({
        id,
        startDate,
        endDate,
        limit,
        sort,
      }),
    {
      keepPreviousData: true,
      ...config,
    }
  );
}

export function useObservationDataById(
  { id, startDate, endDate, limit, sort } = {},
  config = {}
) {
  const applyUnitsToAllItems = useApplyUnits();
  const { select, ...restConfig } = config;

  const isShared = useIsShared();

  const options = {
    useCardDate: isShared
      ? useSharedFilteredCardDataById
      : useFilteredCardDataById,
    useStoryblocks: isShared ? useSharedStoryBlock : useStoryBlockQuery,
  };

  const [processedData, setProcessedData] = useState(null);
  const { data, isLoading, isFetching } = options.useCardDate(
    { id, startDate, endDate, limit, sort },
    restConfig
  );
  const { data: storyblock } = options.useStoryblocks(
    data?.[0]?.storyblockId,
    null
  );

  const dataWithStoryblock = useMemo(
    () => data?.map((item) => ({ ...item, storyblock: storyblock?.[0] })),
    [data, storyblock]
  );

  useEffect(() => {
    if (data) {
      const selectedData =
        select && storyblock ? select(dataWithStoryblock) : data;
      const finalData = applyUnitsToAllItems(selectedData);
      setProcessedData(finalData);
    }
  }, [data, storyblock, select, applyUnitsToAllItems, dataWithStoryblock]);

  return { data: processedData, storyblock, isLoading, isFetching };
}

function useObservationById(id, params) {
  const applyUnitsToAllItems = useApplyUnits();

  return useQuery(
    phrKeys.cardPaginatedDataById(
      id,
      params?.page,
      params?.size,
      params?.sortBy
    ),
    () => PHRApi.getPaginatedCardDataById(id, params),
    {
      select: applyUnitsToAllItems,
      keepPreviousData: true,
      enabled: Boolean(id),
    }
  );
}

export function usePaginatedObservationDataById(args) {
  const params = omit(['id', 'type'], args);
  const id = args?.id;

  const isShared = useIsShared();

  const config = {
    useObservation: isShared ? useSharedObservationById : useObservationById,
    useStoryBlocks: isShared ? useSharedStoryBlock : useStoryBlockQuery,
  };

  const { data, isLoading, isFetching } = config.useObservation(id, {
    ...params,
  });

  const { data: storyblock } = config.useStoryBlocks(
    data?.content?.[0]?.storyblockId,
    {
      select: (storyblocks) => storyblocks[0],
    }
  );

  const dataWithStoryblock = useMemo(() => {
    return {
      ...data,
      content: data?.content?.map((item) => ({
        ...item,
        storyblock: storyblock,
      })),
    };
  }, [data, storyblock]);

  return {
    data: dataWithStoryblock,
    isLoading,
    isFetching,
  };
}

export function useStoryBlockQuery(query, options) {
  const isMulti = Array.isArray(query);
  const ids = isMulti ? query : [query];
  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery(
    phrKeys.storyblock(ids),
    () => fetchAndCacheStoryblocks(ids, queryClient),
    {
      enabled: ids.filter(Boolean).length > 0,
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options,
    }
  );
  return { data, ...rest };
}

export function usePanelById(id) {
  const applyUnitsToAllItems = useApplyUnits();
  const { data, isFetched } = useQuery(
    phrKeys.cardById(id),
    () => PHRApi.getCardById(id),
    {
      select: applyUnitsToAllItems,
      keepPreviousData: true,
      enabled: Boolean(id),
    }
  );

  const { data: storyblock } = useStoryBlockQuery(data?.storyblockId, null);

  const dataWithStoryblock = useMemo(
    () => ({ ...data, storyblock: storyblock?.[0] }),
    [data, storyblock]
  );
  return { dataWithStoryblock, isFetched };
}

export function usePaginatedListDataByType(args) {
  const params = omit(['id', 'type'], args);
  const id = args?.id;

  const { data, isLoading, isFetching } = useQuery(
    phrKeys.cardPaginatedDataById(
      id,
      params?.page,
      params?.size,
      params?.sortBy
    ),
    () => PHRApi.getPaginatedCardDataById(id, params),
    {
      keepPreviousData: true,
      enabled: Boolean(id),
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
}

export function useObservationsGetter({ panelId, page, pageSize }) {
  const applyUnitsToAllItems = useApplyUnits();
  const { data: { pages = [] } = {}, ...rest } = useInfiniteQuery(
    phrKeys.observations([panelId, page]),
    ({ pageParam }) =>
      PHRApi.getFilteredObservations({
        panelId,
        page: pageParam,
        pageSize,
      }).then(applyUnitsToAllItems),
    {
      getNextPageParam: (currentState) => {
        const { last, totalPages, number } = currentState;
        const nextPageNumber = number + 1;

        if (!last && totalPages > nextPageNumber) {
          return nextPageNumber;
        }
        return undefined;
      },
    }
  );
  return {
    pages,
    ...rest,
  };
}
export function useAddDashboardHighlight({ onSuccess }) {
  const queryClient = useQueryClient();

  const { mutate: addHighlight, isLoading } = useMutation(
    PHRApi.addDashboardHighlight,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(phrKeys.dashboardHighlightsOptions());
        onSuccess?.();
      },
    }
  );

  return { addHighlight, isLoading };
}

export function useDeleteDashboardHighlight() {
  const queryClient = useQueryClient();

  const { mutate: deleteHighlight, isLoading } = useMutation(
    PHRApi.deleteDashboardHighlightById,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(phrKeys.dashboardHighlightsOptions());
      },
    }
  );

  return { deleteHighlight, isLoading };
}

export function useDashboardPreferences(config) {
  const applyUnitsToAllItems = useApplyUnits();
  const preferencesQuery = useInfiniteQuery(
    phrKeys.dashboardPreferences(),
    async ({ pageParam = 0 }) => {
      const queryParams = {
        pageSize: 10,
        page: pageParam,
      };
      return PHRApi.getDashboardPreferences(queryParams);
    },
    {
      getNextPageParam: (lastPage) => {
        const { last, number } = lastPage;
        if (last) return;
        return number + 1;
      },
      ...config,
      select: (data) =>
        applyUnitsToAllItems(
          data.pages
            .flatMap((page) => page.content)
            .filter((x) => typeof x?.orderNumber === 'number')
        ),
    }
  );
  const isLoading = preferencesQuery.isLoading;
  const isFetching = preferencesQuery.isFetching;

  return {
    data: preferencesQuery,
    isLoading,
    isFetching,
  };
}

export function useAddDashboardPreferencesFromTimeline() {
  const { mutateAsync: addPreferenceFromTimeline } = useMutation((data) => {
    const dataType = PHR_DATA_TYPE[data?.type];
    const cardId = data?.id;

    if (!dataType) {
      throw new Error(`No data type with value '${dataType}' found!`);
    }

    if (!cardId) {
      throw new Error(`No id provided!`);
    }

    return PHRApi.addDashboardPreferenceByPHR(dataType, cardId);
  });

  return { addPreferenceFromTimeline };
}

export function useDashboardUnusedPreferences(options) {
  const applyUnitsToAllItems = useApplyUnits();
  const { data, isLoading, isFetching } = useQuery(
    phrKeys.dashboardUnusedPreferences(),
    PHRApi.getDashboardUnusedPreferences,
    {
      select: applyUnitsToAllItems,
      cacheTime: Infinity,
      staleTime: Infinity,
      ...options,
    }
  );
  const storyblockIds = useMemo(
    () => data?.map((x) => x?.storyblockId).filter(Boolean),
    [data]
  );
  const { data: storyblocks } = useStoryBlockQuery(storyblockIds, null);

  const { combinedData } = useCombinedData(data, storyblocks);
  return { data: combinedData, isLoading, isFetching };
}

export function useDashboardTopics(preferenceIds, config) {
  const { data: preferenceTopics, isLoading: isLoadingTopics } = useQuery(
    phrKeys.dashboardTopics(),
    () => PHRApi.getDashboardTopics(encodeURI(preferenceIds)),
    config
  );
  return { preferenceTopics, isLoadingTopics };
}

export function useAddDashboardPreference() {
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(
    PHRApi.addDashboardPreferences,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(phrKeys.dashboard());
      },
    }
  );

  return { add, isLoading };
}

export function useRemoveDashboardPreference() {
  const queryClient = useQueryClient();

  const { mutate: remove, isLoading } = useMutation(
    (data) => {
      const cardId = data?.id;
      if (!cardId) {
        throw new Error(`No id provided!`);
      }
      return PHRApi.removeDashboardPreference(cardId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(phrKeys.dashboard());
      },
    }
  );
  return { remove, isLoading };
}

export function useSetDashboardPreferences() {
  const queryClient = useQueryClient();

  const { mutate: set, isLoading } = useMutation(
    PHRApi.setDashboardPreferences,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(phrKeys.dashboard());
      },
    }
  );

  return { set, isLoading };
}

export function useSources(syncing, queryParams) {
  const {
    data: sources,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    phrKeys.sources(queryParams?.search, queryParams),
    async () => PHRApi.getSources(queryParams),
    {
      refetchInterval: syncing ? REFETCH_INTERVAL : 0,
      keepPreviousData: true,
    }
  );

  return { sources, isLoading, isFetching, refetch };
}

export function useConnectedSources({ onSuccess, ...options } = {}) {
  const [syncing, setSyncing] = useState(false);
  const {
    data,
    isLoading: isLoadingConnectedSources,
    refetch,
  } = useQuery(phrKeys.connected(), PHRApi.getConnectedSources, {
    refetchInterval: syncing ? REFETCH_INTERVAL : 0,
    onSuccess: (userSources) => {
      const hasSyncingSources = userSources?.some(
        (userSource) => userSource?.syncStatus === SYNC_STATUSES.SYNCING
      );
      setSyncing(hasSyncingSources);
      onSuccess?.(data);
    },
    cacheTime: syncing ? 0 : Infinity,
    staleTime: syncing ? 0 : Infinity,
    ...options,
  });
  return {
    connectedSources: sortByLastSynced(data),
    isLoadingConnectedSources,
    refetch,
  };
}

export function selectNotHiddenUserSources(sources) {
  return sources.filter((source) => !source?.isHidden);
}

export function useParsedSources() {
  const { data } = usePublicResource('/sources.json');

  const parsedSources = data?.cerner?.entry?.map((entry) => ({
    ...entry?.resource?.contained[0],
    id: entry?.resource?.id,
  }));
  return parsedSources;
}

// const toggle for wearables filters, default false
const showWearables = false;

function getSourcesArray(isAdmin) {
  return [
    features.PHR.PHR_EMRS_ENABLED && {
      label: 'my-phr.timeline.filters.sources.emr-long',
      value: 'EMR',
      isAccordion: true,
    },
    features.PHR.PHR_WEARABLES_ENABLED && {
      label: 'my-phr.timeline.filters.sources.wearable',
      value: 'WEARABLE',
      isAccordion: showWearables,
    },
    {
      label: 'my-phr.timeline.filters.sources.manual',
      value: 'MANUAL',
    },
    (features.PHR.OCR_ENABLED || isAdmin) && {
      label: 'my-phr.timeline.filters.sources.ocr',
      value: 'OCR',
    },
  ].filter(Boolean);
}

export function useSourcesFilters(selected) {
  const { hasRole } = useAuth();
  const isAdmin = hasRole(Roles.ADMIN);
  return useMemo(() => {
    return setSelectedOptions(getSourcesArray(isAdmin), selected);
  }, [isAdmin, selected]);
}

export function useUserSourcesFilters(
  filters,
  sourcesFilters,
  userSources = []
) {
  return useMemo(() => {
    const enabledTypesMap = sourcesFilters.reduce((map, source) => {
      map[source.value] = Boolean(source.isAccordion);
      return map;
    }, {});

    const userSourcesFilters = userSources.reduce((businessIds, source) => {
      const { type: sourceType } = source;
      if (!enabledTypesMap[sourceType]) {
        return businessIds;
      }

      if (!businessIds[sourceType]) {
        businessIds[sourceType] = [];
      }

      businessIds[sourceType].push(source);
      return businessIds;
    }, {});

    Object.keys(userSourcesFilters).forEach((sourceType) => {
      const areAllSelected = filters.sources.includes(sourceType);
      const selected = areAllSelected
        ? userSourcesFilters[sourceType].map(({ value }) => value)
        : filters.userSourceBusinessIds;

      userSourcesFilters[sourceType] = setSelectedOptions(
        userSourcesFilters[sourceType],
        selected
      );
    });

    return userSourcesFilters;
  }, [
    filters.sources,
    filters.userSourceBusinessIds,
    userSources,
    sourcesFilters,
  ]);
}

export function useDateRanges(selected) {
  return useMemo(() => {
    return setSelectedOptions(
      [
        {
          label: 'my-phr.timeline.filters.date-range.anytime',
          value: 'all',
        },
        {
          label: 'my-phr.timeline.filters.date-range.day',
          value: 'days',
        },
        {
          label: 'my-phr.timeline.filters.date-range.week',
          value: 'weeks',
        },
        {
          label: 'my-phr.timeline.filters.date-range.month',
          value: 'months',
        },
        {
          label: 'my-phr.timeline.filters.date-range.year',
          value: 'years',
        },
      ],
      selected
    );
  }, [selected]);
}

export function useFhirAuthorization(authorization, options) {
  const { mutate, isLoading } = useMutation(
    () => PHRApi.emrAuthorization(authorization),
    options
  );
  return { authorize: mutate, isLoading };
}

export function useTerraAuthorization(organizationId) {
  const { refetch } = useQuery(
    phrKeys.terra(organizationId),
    () => PHRApi.terraAuthorization(organizationId),
    { enabled: false }
  );
  return { authorize: refetch };
}
