import { MANUAL_INPUT_TYPE } from '../const';
import manualInputsRegistry from '../manualInputsRegistry';
import OptionalTextInput from './OptionalTextInput';
import RegularTextInput from './RegularTextInput';

export function TextInput(props) {
  const isPanelNote = props?.plainName === 'notes' && props.isGrouped;
  const Component = isPanelNote ? OptionalTextInput : RegularTextInput;
  return <Component {...props} />;
}

manualInputsRegistry.register(MANUAL_INPUT_TYPE.TEXT, TextInput);
