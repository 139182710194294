import Keycloak from 'keycloak-js';

import { getDistributionConfig } from 'distribution';
import { nativeBridge } from 'utils/nativeBridge';

const ssoConfig = getDistributionConfig()?.sso;

const getClientId = () => {
  if (nativeBridge.isWebViewBridgeAvailable) {
    // TODO: this should ideally use REACT_APP_KEYCLOAK_CLIENT_IN_WEBVIEW_MODE
    // There is an issue I cannot resolve at this time, which does not allow it to work properly
    return process.env.REACT_APP_KEYCLOAK_CLIENT;
  }

  return ssoConfig?.client ?? process.env.REACT_APP_KEYCLOAK_CLIENT;
};

export const keycloakConfig = Object.freeze({
  url: ssoConfig?.url ?? process.env.REACT_APP_KEYCLOAK_URL,
  realm: ssoConfig?.realm ?? process.env.REACT_APP_KEYCLOAK_REALM,
  client: getClientId(),
});

export default new Keycloak({
  url: keycloakConfig.url + '/auth/',
  realm: keycloakConfig.realm,
  clientId: keycloakConfig.client,
});
