import { useLibraryAssetRoutedModal } from 'library/hooks';
import LibraryList from 'library/components/LibraryList';
import { WideCard } from 'components/WideCard';
import { Clock } from 'components/icons';

function Duration({ value = '00:00' }) {
  return (
    <div className="mt-2 flex items-center gap-x-1 text-smd-gray-dark">
      <Clock className="h-3.5 w-3.5 shrink-0" />
      <span className="text-smd-sm italic">{value}</span>
    </div>
  );
}

function LibraryPodcastModule({ podcasts }) {
  const { openLibraryAssetModal } = useLibraryAssetRoutedModal({
    assets: podcasts?.map((item) => item?.featuredAsset),
  });

  return (
    <LibraryList
      list={podcasts}
      renderListItem={(item) => {
        const duration = <Duration value={item?.featuredAsset?.duration} />;
        return (
          <div onClick={() => openLibraryAssetModal(item?.featuredAsset)}>
            <WideCard
              key={item?.id}
              title={item?.name}
              duration={duration}
              asset={item?.featuredAsset}
              plainText={item?.description}
              hasSquareShapeThumbnail
            />
          </div>
        );
      }}
    />
  );
}

export default LibraryPodcastModule;
