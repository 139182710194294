import { useEffect, useRef } from 'react';
import { cn } from 'lib/utils';
import InputMask from 'react-input-mask';

import FormController from 'components/forms/Form/FormController';
import { useManualInputDefaultProps } from '../hooks';

const REQUIRED_ERROR = 'required';

export function MaskInput({ mask, ...props }) {
  const newProps = useManualInputDefaultProps(props);
  const ref = useRef(null);

  useEffect(() => {
    const customError = newProps?.error?.type;
    const input = ref?.current?.getInputDOMNode();
    if (customError && customError !== REQUIRED_ERROR && input) {
      input.focus();
    }
  }, [newProps?.error?.type]);

  return (
    <InputMask
      mask={mask}
      className={cn(
        'smd-input-focus-primary !focus:outline-none',
        props.className
      )}
      ref={ref}
      {...newProps}
    />
  );
}

export function ControlledMaskInput({
  name,
  mask,
  control,
  rules = {},
  ...rest
}) {
  return (
    <FormController
      name={name}
      control={control}
      render={({ field }) => <MaskInput mask={mask} {...rest} {...field} />}
    />
  );
}
