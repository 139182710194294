import { ProgressBar, Controls, Thumbnail } from '../components';
import { AudioPlayerProvider } from '../PodcastContext';
import AudioElement from '../AudioElement';
import { darkTheme } from '../themes';

function AssetPodcastPlayer(props) {
  const { url, thumbnail, alt } = props;
  return (
    <div className="flex flex-col gap-y-4 bg-smd-gray-darker p-4 shadow-md">
      <Thumbnail thumbnail={thumbnail} alt={alt} />
      <ProgressBar />
      <Controls />
      <AudioElement theme={darkTheme} src={url} />
    </div>
  );
}

function LightboxPodcast(props) {
  return (
    <AudioPlayerProvider>
      <AssetPodcastPlayer {...props?.config} />
    </AudioPlayerProvider>
  );
}

export default LightboxPodcast;
