import { useTranslation } from 'react-i18next';
import { isNil, mergeAll } from 'ramda';
import classnames from 'classnames';

import { useMedia, useTitleHeight } from 'hooks';
import { filterThemeLogos, getFocalPosition } from 'utils';
import { DEFAULT_CARD_THEME } from 'journals/const';
import { PictureResource } from './media';
import { BlurredLayer, blurredStyle } from './BlurredLayer';
import { Podcast } from './icons';

function Sponsors({ logos = [], theme }) {
  const { t } = useTranslation();
  const sponsoredLogo = logos.find(({ sponsor }) => Boolean(sponsor));
  const filteredLogos = filterThemeLogos(logos, theme ?? DEFAULT_CARD_THEME);

  const defaultLogo = filteredLogos[0];

  if (isNil(sponsoredLogo) && isNil(defaultLogo)) return null;

  return (
    <div className="flex h-4.5 shrink-0 items-center space-x-2 overflow-hidden">
      {sponsoredLogo && (
        <>
          <span className="text-smd-sm italic">{t('common.sponsored-by')}</span>
          <img className="h-full" src={sponsoredLogo.url} alt="" />
        </>
      )}
      {!sponsoredLogo && defaultLogo && (
        <img className="h-full" src={defaultLogo.url} alt="" />
      )}
    </div>
  );
}

export default function Card({
  heading,
  content,
  cover,
  logos,
  theme,
  className,
  imgStyle,
  bodyStyle,
  preamble,
  hasPodcast,
  ...props
}) {
  const { url, focalPoint, alt, images } = cover ?? {};
  const isLarge = useMedia(useMedia.LARGE);
  const { t } = useTranslation();

  const { position, isValid } = getFocalPosition(focalPoint);
  const srcPath = cover?.images?.[0]?.path;

  const pictureStyle = {
    objectPosition: isValid ? position : 'center',
    objectFit: 'cover',
    height: isLarge ? '55%' : '70%',
    width: '100%',
    ...imgStyle,
  };

  const titleId = `title=${preamble?.title}`;

  const hasPreamblePodcast = Boolean(
    preamble?.podcast?.[0]?.libraryContent?.url
  );

  const withPodcast = hasPodcast || hasPreamblePodcast;

  const contentLines = useTitleHeight(
    titleId,
    bodyStyle,
    preamble,
    'journal',
    logos,
    withPodcast
  );

  const isSensitive = Boolean(cover?.tags?.['safe-search']?.length);

  return (
    <article
      className={classnames(
        'relative flex flex-col bg-white text-smd-main antialiased shadow-md',
        className
      )}
      {...props}
    >
      <BlurredLayer
        isSensitive={isSensitive}
        style={{ maxHeight: pictureStyle.height }}
      >
        {({ isBlurred }) => (
          <PictureResource
            config={{
              url: url || srcPath,
              alt,
              images,
            }}
            style={
              isBlurred ? mergeAll([pictureStyle, blurredStyle]) : pictureStyle
            }
            loading="lazy"
          />
        )}
      </BlurredLayer>

      <div
        style={{ height: isLarge ? '45%' : '30%', ...bodyStyle }}
        className="flex flex-col justify-between p-smd-sm"
      >
        <div className="space-y-1">
          <h3
            id={`title=${preamble?.title}`}
            className="smd-group-focus-visible-primary line-clamp-3 shrink-0 rounded-sm pr-8 text-smd-base font-bold lg:line-clamp-4 lg:pr-0"
          >
            {heading}
          </h3>

          {withPodcast && (
            <p className="flex items-center gap-1 md:gap-2">
              <Podcast className="h-5 w-5 shrink-0 rounded-full bg-smd-gray-lightest p-1 text-smd-accent md:h-7 md:w-7" />
              <span className="text-smd-xs text-smd-gray-dark">
                {t('podcasts.podcast-available')}
              </span>
            </p>
          )}
          <p
            className={classnames(
              'max-w-5/6 text-smd-sm text-smd-secondary lg:max-w-full',
              contentLines
            )}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        {logos ? <Sponsors logos={logos} theme={theme} /> : null}
      </div>
    </article>
  );
}
