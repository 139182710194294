import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/forms';
import { useManualInputDefaultProps } from '../hooks';
import { TextAreaInput } from './TextAreaInput';

export default function OptionalTextInput({ resetField, ...props }) {
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState(false);

  const newProps = useManualInputDefaultProps(props);

  function onToggle() {
    setIsShown((value) => !value);
  }

  useEffect(() => {
    if (!isShown) {
      resetField(newProps?.name);
    }
  }, [isShown, newProps?.name, resetField]);

  return (
    <div className="flex w-full flex-col items-start">
      {isShown && (
        <TextAreaInput
          groupClassName="w-full [&_textarea]:max-h-52"
          placeholder={newProps?.placeholder}
          register={newProps?.register}
          id={newProps?.name}
          autoExpand
          name={newProps?.name}
          rules={newProps?.rules}
          rows={1}
          errors={newProps?.errors}
        />
      )}
      <Button
        type="button"
        color="anchor"
        size="none"
        className="text-left"
        onClick={() => onToggle()}
      >
        {t(
          isShown
            ? 'my-phr.manual-inputs.remove-note'
            : 'my-phr.manual-inputs.add-note'
        )}
      </Button>
    </div>
  );
}
