import { useState } from 'react';
import { isEmpty, isNil } from 'ramda';
import { getAssetUrl } from 'utils';

function Thumbnail({ thumbnail, alt }) {
  const [isError, setIsError] = useState(false);
  const isMissingThumbnail = isNil(thumbnail) || isEmpty(thumbnail);
  const assetUrl = getAssetUrl(thumbnail);
  if (isMissingThumbnail || isError) {
    return null;
  }
  return (
    <img
      onError={() => setIsError(true)}
      src={assetUrl}
      alt={alt}
      className="h-80 w-80 object-cover"
    />
  );
}

export default Thumbnail;
