import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { X } from 'components/icons';
import { nativeBridge } from 'utils/nativeBridge';

const browserCheck = (browser, expectedVersion) => {
  const matchedBrowser = navigator.userAgent
    .toLowerCase()
    .match(new RegExp(`${browser}+\/?(\\d+)`, 'i')); // eslint-disable-line no-useless-escape
  return matchedBrowser != null && matchedBrowser[1] >= expectedVersion;
};

// -------------------------------------------------------
// Most Popular browser versions released in 2019:
// https://en.wikipedia.org/wiki/List_of_web_browsers
// -------------------------------------------------------
// - Chrome 72–79
// - Firefox 65–71
// - Microsoft Edge
// - Opera 58–65
// - Safari 13
// -------------------------------------------------------
// Another metric used for determining which versions to trigger a warning:
// https://caniuse.com/usage-table
// -------------------------------------------------------

const browserListChecks = {
  chrome: () => {
    return browserCheck('chrome', 78);
  },
  firefox: () => {
    return browserCheck('firefox', 77);
  },
  edge: () => {
    return browserCheck('edg', 88);
  },
  safari: () => {
    return (
      browserCheck('safari', 13) &&
      !navigator.userAgent.toLowerCase().includes('chrome') //chrome userAgent includes safari
    );
  },
  opera: () => {
    return browserCheck('opr', 72);
  },
};

const checkIfIntegratedBrowser = (userAgent) =>
  userAgent.includes('instagram') ||
  userAgent.includes('messenger') ||
  userAgent.includes('linkedin') ||
  userAgent.includes('pinterest') ||
  userAgent.includes('twitter') ||
  userAgent.includes('fb');

function OldBrowserWarning() {
  const { t } = useTranslation();
  const [showWarning, setShowWarning] = useState();

  const isBrowserVersionSupported = useCallback(() => {
    if (checkIfIntegratedBrowser(navigator.userAgent.toLowerCase())) {
      return true;
    }
    return Object.values(browserListChecks).reduce(
      (isBrowserVersionSupported, browserCheck) =>
        isBrowserVersionSupported || browserCheck(),
      false
    );
  }, []);

  useEffect(() => {
    setShowWarning(!isBrowserVersionSupported());
  }, [isBrowserVersionSupported]);

  let warning = '';

  if (showWarning && !nativeBridge.isWebViewBridgeAvailable) {
    warning = (
      <div className="absolute inset-x-0 bottom-0 z-10 mx-auto grid w-[90vw] transform bg-smd-accent px-4">
        <button
          onClick={() => setShowWarning(false)}
          className="absolute right-0 top-0 mr-3 mt-3 align-middle text-white focus:outline-none"
          aria-label={t('labels.common.close')}
        >
          <X strokeWidth="2" className="text-bold h-5 w-5" />
        </button>
        <div className="mt-6 text-xl font-semibold text-white">
          {t('errors.old-browser-warning.before')}
        </div>
        <div className="mb-6 mt-4 whitespace-pre-line text-xs text-white">
          {t('errors.old-browser-warning.title')}{' '}
          <span className="font-bold">{t('common.browsers.safari')}</span>,{' '}
          <span className="font-bold">{t('common.browsers.chrome')}</span>,{' '}
          <span className="font-bold">{t('common.browsers.firefox')}</span>{' '}
          {t('common.or')}{' '}
          <span className="font-bold">{t('common.browsers.edge')}</span>.
        </div>
      </div>
    );
  }
  return warning;
}

export default OldBrowserWarning;
